<template>
    <Modal :mask-closable="false" :closable="false" v-model="isShow" title="基本信息" width="500" :loading="ajaxLoading">
        <div class="form_item">
            <div class="form_label must_input">旧密码</div>
            <Input class="width_300" type="password" password placeholder="请输入旧密码" v-model.trim="inputData.oldPwd" />
        </div>
        <div class="form_item">
            <div class="form_label">新密码</div>
            <Input class="width_300" type="password" password placeholder="请输入新密码" v-model.trim="inputData.newPwd" />
        </div>
        <div class="form_item">
            <div class="form_label">确认新密码</div>
            <Input class="width_300" type="password" password placeholder="请确认新密码" v-model.trim="inputData.newPwd2" />
        </div>

        <div slot="footer" class="relative width_100_percent flex align_center justify_center">
            <Button type="primary" ghost @click="onCancel">关闭</Button>
            <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirm">确定</Button>
        </div>
    </Modal>
</template>
<script>
import localstorage from '@/lib/localstorage';
import { SET_USERINFO } from '@/store/mutationsType';
import { mapGetters } from 'vuex';
import { validateForm } from '@/lib/util';
import { reqSavePwd } from '@/lib/request/auth2';

export default {
    name: 'updatePassword',

    props: ['value'],
    data() {
        return {
            isShow: false,
            formData: {
                id: null,
                groupid: null,
                userName: null,
                mobile: null,
            },
            inputData: {
                oldPwd: null,
                newPwd: null,
                newPwd2: null,
            },
        };
    },
    watch: {
        value(newValue) {
            this.isShow = newValue;
        },
    },
    mounted() {
        let userInfo = this.getUserInfo();
        this.formData.id = userInfo.id;
        this.formData.groupid = userInfo.groupid;
        this.formData.userName = userInfo.userName;
    },
    computed: {
        ...mapGetters(['userInfo']),
    },
    methods: {
        getUserInfo() {
            return Object.keys(this.userInfo || {}).length == 0 ? localstorage.get(SET_USERINFO) || {} : this.userInfo;
        },
        onCancel() {
            this.isShow = false;
            this.$emit('input', false);
            window.setTimeout(() => {
                this.hideAjaxLoading();
            }, 500);
        },
        onConfirm() {
            let { oldPwd, newPwd, newPwd2 } = this.inputData;
            let vs = [
                { value: oldPwd, tip: '请输入旧密码' },
                { value: newPwd, tip: '请输入新密码' },
                { value: newPwd2, tip: '请确认新密码' },
            ];
            validateForm(vs)
                .then(() => {
                    if (newPwd != newPwd2) {
                        this.fadeWarning('两次密码不一致，请重新输入');
                        return;
                    }
                    this.showAjaxLoading();
                    reqSavePwd({
                        id: this.formData.id,
                        oldPassWord: oldPwd,
                        passWord: newPwd,
                    })
                        .then(res => {
                            this.fadeAlert('操作成功');
                            this.onCancel();
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.fadeWarning(msg);
                });
        },
    },
};
</script>
<style lang="less" scoped>
.form_label {
    width: 120px;
}
</style>

<template>
    <div class="layout pages">
        <Layout class="pages">
            <Header>
                <Menu mode="horizontal" class="menu_top flex align_center space_between" theme="dark" active-name="1">
                    <div class="layout-logo flex align_center">
                        <img class="logo" src="../../assets/logo.png" />
                        <!-- <div class="company_name">琪朗灯光</div> -->
                        <div class="flex align_center justify_center ml_20 layout-header-bar" @click="collapsedSider">
                            <Icon :class="rotateIcon" :style="{ margin: '0 20px' }" type="md-menu" size="24"></Icon>
                        </div>
                    </div>
                    <div class="flex align_center right_top_btns">
                        <div class="btn account">
                            <Icon type="ios-contact"></Icon>
                            欢迎您：
                            <Poptip v-model="showUserPoptip" :transfer="true" placement="bottom-end" trigger="click">
                                <Button type="primary" ghost>{{ getUserInfo().nickName }}</Button>
                                <div class="flex column align_center" slot="content">
                                    <Button type="success" ghost @click="onShowBaseMessage">基本信息</Button>
                                    <Button class="mt_15" type="warning" ghost @click="onShowUpdatePwd">修改密码</Button>
                                </div>
                            </Poptip>
                        </div>
                        <div class="btn exit_login_btn" @click="onExitLogin">
                            <Icon type="md-exit"></Icon>
                            退出登录
                        </div>
                    </div>
                </Menu>
            </Header>

            <Layout class="relative" :style="{ height: contentHeight + 'px' }">
                <Sider ref="sliderRef" hide-trigger breakpoint="xl" collapsible :collapsed-width="0" :style="{ background: '#fff' }" v-model="isCollapsed">
                    <Menu accordion :active-name="activeMenuName" theme="light" width="auto" :open-names="openNames">
                        <template v-for="menu in realPageMenus">
                            <MenuItem class="fontsize_16 font_weight" v-if="menu.meta.hideChildren || !menu.children" :key="menu.name" :name="menu.name" :to="menu.path">{{ menu.meta.title }}</MenuItem>
                            <template v-else-if="menu.children">
                                <Submenu :name="menu.name" :key="menu.name">
                                    <template slot="title">
                                        <b>{{ menu.meta.title }}</b>
                                    </template>
                                    <MenuItem v-for="subMenu in menu.children" :key="subMenu.name" :name="subMenu.name" :to="subMenu.path">{{ subMenu.meta.title }}</MenuItem>
                                </Submenu>
                            </template>
                        </template>
                    </Menu>
                </Sider>

                <Layout class="relative" :style="{ padding: '0 24px 24px', overflowX: 'auto', height: '100%' }">
                    <Breadcrumb :style="{ margin: '24px 0' }">
                        <BreadcrumbItem v-for="(detail, idx) in currentMenuRoute" :key="'title_' + encodeURIComponent(detail.meta.title) + idx">
                            <a v-if="detail.meta.click" @click="goUrl(detail.path)">{{ detail.meta.title }}</a>
                            <template v-else>{{ detail.meta.title }}</template>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <Content class="relative overflow_auto" :style="{ padding: '24px', minWidth: '800px', minHeight: '280px', background: '#fff', borderRadius: '12px' }">
                        <div class="watermark">
                            <div class="watermarkItem" v-for="(item, index) in 20" :key="'username_' + index" :style="` transform: rotate(-30deg) translateX(-${Math.floor(index / 4) * 150}px)`">
                                Kinglong琪朗
                                <div>{{ getUserInfo().nickName }}</div>
                            </div>
                        </div>
                        <router-view></router-view>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
        <userBaseMessage v-model="showBaseMessage"></userBaseMessage>
        <updatePassword v-model="showUpdatePassword"></updatePassword>
        <mySpin :loading="ajaxLoading"></mySpin>
    </div>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep';
import { pageMenus, filterAsyncRouter } from '@/router/routes';
import { loginout } from '@/lib/request/auth';
import { mapGetters } from 'vuex';
import localstorage from '@/lib/localstorage';
import { SET_USERINFO } from '@/store/mutationsType';
import userBaseMessage from '@/components/userBaseMessage';
import updatePassword from '@/components/updatePassword';

export default {
    components: {
        userBaseMessage,
        updatePassword,
    },
    data() {
        return {
            isCollapsed: false,
            realPageMenus: [],
            contentHeight: 0,
            showUserPoptip: false,
            showBaseMessage: false,
            showUpdatePassword: false,
        };
    },
    created() {
        let h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        this.contentHeight = h - 64;
    },
    computed: {
        ...mapGetters(['userInfo']),
        rotateIcon() {
            return ['menu-icon', this.isCollapsed ? 'rotate-icon' : ''];
        },
        menuitemClasses() {
            return ['menu-item', this.isCollapsed ? 'collapsed-menu' : ''];
        },
        currentMenuRoute() {
            let cname = this.currentRoute.name;
            return this.getRouteByName(cname, this.realPageMenus) || [];
        },
        openNames() {
            let names = this.currentMenuRoute.map(item => item.name);
            return names;
        },
        activeMenuName() {
            let names = this.currentMenuRoute.map(item => item.name);
            let name = names.length >= 2 ? names.slice(-2, -1)[0] : names[0];
            return name;
        },
    },
    watch: {
        $store: {
            handler(n) {
                console.log('index index.vue watch this.$store ', n.state.user.routes);
                this.setRealPageMenus();
            },
            // deep: true,
        },
    },
    mounted() {
        // this.windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        // window.addEventListener('resize', () => {
        //     this.windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        // });
        console.log('index index.vue mounted');
        this.setRealPageMenus();
    },
    methods: {
        getUserInfo() {
            return Object.keys(this.userInfo || {}).length == 0 ? localstorage.get(SET_USERINFO) || {} : this.userInfo;
        },
        collapsedSider() {
            this.$refs.sliderRef.toggleCollapse();
        },
        setRealPageMenus() {
            this.realPageMenus = filterAsyncRouter(cloneDeep(pageMenus)) || [];
        },
        getRouteByName(name, list = [], out = []) {
            for (let i = 0; i < list.length; i++) {
                let item = list[i];
                if (item.name && item.name == name) {
                    item.meta && item.meta.title && out.push(item);
                    if (out.length == 1) {
                        return out;
                    }
                    return true;
                }
                if (item.children) {
                    item.meta && item.meta.title && out.push(item);
                    let children = this.getRouteByName(name, item.children, out);
                    if (children) {
                        return out;
                    }
                    out.pop();
                }
            }
            return false;
        },
        onAccount() {
            // this.goUrl('/accountList');
            console.log('onAccount...');
        },
        onExitLogin() {
            this.confirmDialog('确认退出吗？').then(isOK => {
                if (isOK == 1) {
                    this.showAjaxLoading();
                    loginout()
                        .then(() => {
                            //
                        })
                        .finally(() => {
                            this.hideAjaxLoading();
                        });
                    this.exit();
                }
            });
        },
        onShowBaseMessage() {
            this.showUserPoptip = false;
            this.showBaseMessage = true;
        },
        onShowUpdatePwd() {
            this.showUserPoptip = false;
            this.showUpdatePassword = true;
        },
    },
};
</script>
<style lang="less" scoped>
.layout {
    border: 1px solid #d7dde4;
    background: #f5f7f9;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    & /deep/ .ivu-layout-sider-children {
        overflow-y: auto;
    }
}

.menu_top {
    user-select: none;
    .layout-logo {
        .logo {
            max-width: 200px;
            max-height: 60px;
            object-fit: contain;
        }
        .company_name {
            color: #fff;
            margin-left: 10px;
        }
    }
    .right_top_btns {
        color: #fff;
        /deep/ .ivu-btn-ghost {
            color: #fff;
        }

        .btn {
            cursor: pointer;
            &.exit_login_btn {
                margin-left: 20px;
            }
        }
    }
    .layout-nav {
        width: 240px;
    }
}

.layout-header-bar {
    width: 40px;
    height: 40px;
    // background: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    color: #fff;
}
.menu-icon {
    transition: all 0.3s;
}
.rotate-icon {
    transform: rotate(-90deg);
}
.menu-item span {
    display: inline-block;
    overflow: hidden;
    width: 69px;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: bottom;
    transition: width 0.2s ease 0.2s;
}
.menu-item i {
    transform: translateX(0px);
    transition: font-size 0.2s ease, transform 0.2s ease;
    vertical-align: middle;
    font-size: 16px;
}
.collapsed-menu span {
    width: 0px;
    transition: width 0.2s ease;
}
.collapsed-menu i {
    transform: translateX(5px);
    transition: font-size 0.2s ease 0.2s, transform 0.2s ease 0.2s;
    vertical-align: middle;
    font-size: 22px;
}

.watermark {
    position: fixed;
    left: 0;
    top: 0;
    width: 110vw;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    opacity: 0.1;
    z-index: 9999;
    pointer-events: none;
    user-select: none;
    transform: translate(200px, 50px);

    .watermarkItem {
        pointer-events: none;
        user-select: none;
        font-size: 28px;
        white-space: nowrap;
        height: 33%;
        transform: rotate(-30deg);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;

        > div {
            font-size: 20px;
        }
    }
}
</style>

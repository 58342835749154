<template>
    <Modal :mask-closable="false" :closable="false" v-model="isShow" title="基本信息" width="500" :loading="ajaxLoading">
        <div class="form_item">
            <div class="form_label align_self_start">头像</div>
            <previewImage v-if="!!formData.userImg" :thumWidth="100" :thumHeight="100" :src="formData.userImg"></previewImage>
        </div>
        <div class="form_item">
            <div class="form_label">昵称</div>
            {{ formData.nickName }}
        </div>
        <div class="form_item">
            <div class="form_label">手机号码</div>
            {{ formData.mobile || '' }}
        </div>
        <div class="form_item">
            <div class="form_label">邮箱</div>
            {{ formData.email || '' }}
        </div>

        <div slot="footer" class="relative width_100_percent flex align_center justify_center">
            <Button type="primary" ghost @click="onCancel">关闭</Button>
            <!-- <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirm">确定</Button> -->
        </div>
    </Modal>
</template>
<script>
import localstorage from '@/lib/localstorage';
import { SET_USERINFO } from '@/store/mutationsType';
import { mapGetters } from 'vuex';
import previewImage from '@/components/previewImage';

export default {
    name: 'userBaseMessage',
    components: {
        previewImage,
    },
    props: ['value'],
    data() {
        return {
            isShow: false,
            formData: {
                id: null,
                nickName: null,
                userImg: null,
                mobile: null,
                email: null,
            },
        };
    },
    watch: {
        value(newValue) {
            this.isShow = newValue;
        },
    },
    mounted() {
        let userInfo = this.getUserInfo();
        this.formData.id = userInfo.id;
        this.formData.nickName = userInfo.nickName;
        this.formData.userImg = userInfo.userImg;
    },
    computed: {
        ...mapGetters(['userInfo']),
    },
    methods: {
        getUserInfo() {
            return Object.keys(this.userInfo || {}).length == 0 ? localstorage.get(SET_USERINFO) || {} : this.userInfo;
        },
        onCancel() {
            this.isShow = false;
            this.$emit('input', false);
            window.setTimeout(() => {
                this.hideAjaxLoading();
            }, 500);
        },
        onConfirm() {
            this.fadeAlert('操作成功');
            this.onCancel();
        },
    },
};
</script>
<style lang="less" scoped>
.form_label {
    width: 120px;
}
</style>
